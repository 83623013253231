module.exports = [
  {
    'languageCode': 'fa',
    'name': 'Persian',
    'localizedName': 'فارسی',
    'locale': {
      'flagUrl': '',
      'locale': 'fa-af'
    }
  },
  {
    'languageCode': 'ps',
    'name': 'Pashto',
    'localizedName': 'پښتو',
    'locale': {
      'locale': 'ps-af',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'sq',
    'name': 'Albanian',
    'localizedName': 'Shqip',
    'locale': {
      'locale': 'sq-al',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'hy',
    'name': 'Armenian',
    'localizedName': 'Հայերեն',
    'locale': {
      'flagUrl': '',
      'locale': 'hy-am'
    }
  },
  {
    'languageCode': 'bn',
    'name': 'Bengali',
    'localizedName': 'বাংলা',
    'locale': {
      'locale': 'bn-bd',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'be',
    'name': 'Belarusian',
    'localizedName': 'беларуская мова',
    'locale': {
      'locale': 'be-by',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'yo',
    'name': 'Yoruba',
    'localizedName': 'Yorùbá',
    'locale': {
      'locale': 'yo-bj',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'bs',
    'name': 'Bosnian',
    'localizedName': 'Bosanski jezik',
    'locale': {
      'locale': 'bs-ba',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'pt',
    'name': 'Portuguese',
    'localizedName': 'português',
    'locale': {
      'flagUrl': '',
      'locale': 'pt-br'
    }
  },
  {
    'languageCode': 'bg',
    'name': 'Bulgarian',
    'localizedName': 'български език',
    'locale': {
      'flagUrl': '',
      'locale': 'bg-bg'
    }
  },
  {
    'languageCode': 'km',
    'name': 'Khmer',
    'localizedName': 'ខ្មែរ',
    'locale': {
      'locale': 'km-kh',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'zh',
    'name': 'Chinese',
    'localizedName': '中文',
    'locale': {
      'locale': 'zh-cn',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'hr',
    'name': 'Croatian',
    'localizedName': 'Hrvatski jezik',
    'locale': {
      'locale': 'hr-hr',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'da',
    'name': 'Danish',
    'localizedName': 'Dansk',
    'locale': {
      'locale': 'da-dk',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'et',
    'name': 'Estonian',
    'localizedName': 'Eesti',
    'locale': {
      'locale': 'et-ee',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'fj',
    'name': 'Fijian',
    'localizedName': 'vosa Vakaviti',
    'locale': {
      'flagUrl': '',
      'locale': 'fj-fj'
    }
  },
  {
    'languageCode': 'fi',
    'name': 'Finnish',
    'localizedName': 'Suomi',
    'locale': {
      'flagUrl': '',
      'locale': 'fi-fi'
    }
  },
  {
    'languageCode': 'fr',
    'name': 'French',
    'localizedName': 'Français',
    'locale': {
      'flagUrl': '',
      'locale': 'fr-fr'
    },
    'countries': [
      {
        'locale': 'fr-ca',
        'flagUrl': ''
      },
      {
        'locale': 'fr-be',
        'flagUrl': ''
      }
    ]
  },
  {
    'languageCode': 'de',
    'name': 'German',
    'localizedName': 'Deutsch',
    'locale': {
      'flagUrl': 'Germany',
      'locale': 'de-de'
    },
    'countries': [
      {
        'locale': 'de-at',
        'flagUrl': ''
      },
      {
        'locale': 'de-li',
        'flagUrl': ''
      },
      {
        'locale': 'de-ch',
        'flagUrl': ''
      },
      {
        'locale': 'de-be',
        'flagUrl': ''
      },
      {
        'flagUrl': '',
        'locale': 'de-lu'
      }
    ]
  },
  {
    'languageCode': 'el',
    'name': 'Greek',
    'localizedName': 'ελληνικά',
    'locale': {
      'flagUrl': '',
      'locale': 'el-gr'
    }
  },
  {
    'languageCode': 'ht',
    'name': 'Haitian Creole',
    'localizedName': 'Kreyòl ayisyen',
    'locale': {
      'flagUrl': '',
      'locale': 'ht-ht'
    }
  },
  {
    'languageCode': 'hu',
    'name': 'Hungarian',
    'localizedName': 'Magyar',
    'locale': {
      'flagUrl': '',
      'locale': 'hu-hu'
    }
  },
  {
    'languageCode': 'is',
    'name': 'Icelandic',
    'localizedName': 'Islenska',
    'locale': {
      'flagUrl': '',
      'locale': 'is-is'
    }
  },
  {
    'languageCode': 'gu',
    'name': 'Gujarati',
    'localizedName': 'ગુજરાતી',
    'locale': {
      'flagUrl': '',
      'locale': 'gu-in'
    }
  },
  {
    'languageCode': 'hi',
    'name': 'Hindi',
    'localizedName': 'हिन्दी',
    'locale': {
      'flagUrl': '',
      'locale': 'hi-in'
    }
  },
  {
    'languageCode': 'kn',
    'name': 'Kannada',
    'localizedName': 'ಕನ್ನಡ',
    'locale': {
      'flagUrl': '',
      'locale': 'kn-in'
    }
  },
  {
    'languageCode': 'ml',
    'name': 'Malayalam',
    'localizedName': 'മലയാളം',
    'locale': {
      'flagUrl': '',
      'locale': 'ml-in'
    }
  },
  {
    'languageCode': 'sd',
    'name': 'Sindhi',
    'localizedName': 'सिन्धी',
    'locale': {
      'flagUrl': '',
      'locale': 'sd-in'
    }
  },
  {
    'languageCode': 'ta',
    'name': 'Tamil',
    'localizedName': 'தமிழ்',
    'locale': {
      'flagUrl': '',
      'locale': 'ta-in'
    }
  },
  {
    'languageCode': 'te',
    'name': 'Telugu',
    'localizedName': 'తెలుగు',
    'locale': {
      'flagUrl': '',
      'locale': 'te-in'
    }
  },
  {
    'languageCode': 'id',
    'name': 'Indonesian',
    'localizedName': 'Bahasa Indonesia',
    'locale': {
      'flagUrl': '',
      'locale': 'id-id'
    }
  },
  {
    'languageCode': 'su',
    'name': 'Sundanese',
    'localizedName': 'Basa Sunda',
    'locale': {
      'flagUrl': '',
      'locale': 'su-id'
    }
  },
  {
    'languageCode': 'ku',
    'name': 'Kurdish',
    'localizedName': 'Kurdî',
    'locale': {
      'flagUrl': '',
      'locale': 'ku-iq'
    }
  },
  {
    'languageCode': 'gl',
    'name': 'Galician',
    'localizedName': 'Galego',
    'locale': {
      'flagUrl': '',
      'locale': 'gl-ie'
    },
    'countries': [
      {
        'locale': 'gl-es',
        'flagUrl': ''
      }
    ]
  },
  {
    'languageCode': 'he',
    'name': 'Hebrew',
    'localizedName': 'עברית',
    'locale': {
      'flagUrl': '',
      'locale': 'he-il'
    }
  },
  {
    'languageCode': 'yi',
    'name': 'Yiddish',
    'localizedName': 'ייִדיש',
    'locale': {
      'flagUrl': '',
      'locale': 'yi-il'
    }
  },
  {
    'languageCode': 'co',
    'name': 'Corsican',
    'localizedName': 'corsu, lingua corsa',
    'locale': {
      'flagUrl': '',
      'locale': 'co-it'
    }
  },
  {
    'languageCode': 'it',
    'name': 'Italian',
    'localizedName': 'italiano',
    'locale': {
      'flagUrl': '',
      'locale': 'it-it'
    }
  },
  {
    'languageCode': 'la',
    'name': 'Latin',
    'localizedName': 'latine',
    'locale': {
      'flagUrl': '',
      'locale': 'la-it'
    }
  },
  {
    'languageCode': 'ja',
    'name': 'Japanese',
    'localizedName': '日本語',
    'locale': {
      'flagUrl': '',
      'locale': 'ja-jp'
    }
  },
  {
    'languageCode': 'kk',
    'name': 'Kazakh',
    'localizedName': 'қазақ тілі',
    'locale': {
      'flagUrl': '',
      'locale': 'kk-kz'
    }
  },
  {
    'languageCode': 'sw',
    'name': 'Swahili',
    'localizedName': 'Kiswahili',
    'locale': {
      'flagUrl': '',
      'locale': 'sw-ke'
    }
  },
  {
    'languageCode': 'lo',
    'name': 'Lao',
    'localizedName': 'ພາສາລາວ',
    'locale': {
      'flagUrl': '',
      'locale': 'lo-la'
    }
  },
  {
    'languageCode': 'lv',
    'name': 'Latvian',
    'localizedName': 'latviešu valoda',
    'locale': {
      'flagUrl': '',
      'locale': 'lv-lv'
    }
  },
  {
    'languageCode': 'lt',
    'name': 'Lithuanian',
    'localizedName': 'lietuvių kalba',
    'locale': {
      'flagUrl': '',
      'locale': 'lt-lt'
    }
  },
  {
    'languageCode': 'mk',
    'name': 'Macedonian',
    'localizedName': 'македонски јазик',
    'locale': {
      'flagUrl': '',
      'locale': 'mk-mk'
    }
  },
  {
    'languageCode': 'mg',
    'name': 'Malagasy',
    'localizedName': 'fiteny malagasy',
    'locale': {
      'flagUrl': '',
      'locale': 'mg-mg'
    }
  },
  {
    'languageCode': 'ms',
    'name': 'Malay',
    'localizedName': 'bahasa Melayu',
    'locale': {
      'flagUrl': '',
      'locale': 'ms-my'
    }
  },
  {
    'languageCode': 'mt',
    'name': 'Maltese',
    'localizedName': 'Malti',
    'locale': {
      'flagUrl': '',
      'locale': 'mt-mt'
    }
  },
  {
    'languageCode': 'mn',
    'name': 'Mongolian',
    'localizedName': 'монгол',
    'locale': {
      'flagUrl': '',
      'locale': 'mn-mn'
    }
  },
  {
    'languageCode': 'ne',
    'name': 'Nepali',
    'localizedName': 'नेपाली',
    'locale': {
      'flagUrl': '',
      'locale': 'ne-np'
    }
  },
  {
    'languageCode': 'ha',
    'name': 'Hausa',
    'localizedName': 'هَوُسَ',
    'locale': {
      'flagUrl': '',
      'locale': 'ha-ng'
    }
  },
  {
    'languageCode': 'ig',
    'name': 'Igbo',
    'localizedName': 'Asụsụ Igbo',
    'locale': {
      'flagUrl': '',
      'locale': 'ig-ng'
    }
  },
  {
    'languageCode': 'no',
    'name': 'Norwegian',
    'localizedName': 'Norsk',
    'locale': {
      'flagUrl': '',
      'locale': 'no-no'
    }
  },
  {
    'languageCode': 'tl',
    'name': 'Tagalog',
    'localizedName': '',
    'locale': {
      'flagUrl': '',
      'locale': 'tl-ph'
    }
  },
  {
    'languageCode': 'pl',
    'name': 'Polish',
    'localizedName': 'polski',
    'locale': {
      'flagUrl': '',
      'locale': 'pl-pl'
    }
  },
  {
    'languageCode': 'ro',
    'name': 'Romanian',
    'localizedName': 'limba română',
    'locale': {
      'flagUrl': '',
      'locale': 'ro-ro'
    }
  },
  {
    'languageCode': 'ru',
    'name': 'Russian',
    'localizedName': 'Русский',
    'locale': {
      'flagUrl': 'Russia',
      'locale': 'ru-ru'
    }
  },
  {
    'languageCode': 'sm',
    'name': 'Samoan',
    'localizedName': 'gagana fa\'a Samoa',
    'locale': {
      'flagUrl': '',
      'locale': 'sm-ws'
    }
  },
  {
    'languageCode': 'sr',
    'name': 'Serbian',
    'localizedName': 'српски језик',
    'locale': {
      'flagUrl': '',
      'locale': 'sr-rs'
    }
  },
  {
    'languageCode': 'sk',
    'name': 'Slovak',
    'localizedName': 'slovenčina',
    'locale': {
      'flagUrl': '',
      'locale': 'sk-sk'
    }
  },
  {
    'languageCode': 'sl',
    'name': 'Slovenian',
    'localizedName': 'slovenski jezik',
    'locale': {
      'locale': 'sl-si',
      'flagUrl': ''
    }
  },
  {
    'languageCode': 'so',
    'name': 'Somali',
    'localizedName': 'Soomaaliga',
    'locale': {
      'flagUrl': '',
      'locale': 'so-so'
    }
  },
  {
    'languageCode': 'af',
    'name': 'Afrikaans',
    'localizedName': '',
    'locale': {
      'flagUrl': '',
      'locale': 'af-za'
    }
  },
  {
    'languageCode': 'xh',
    'name': 'Xhosa',
    'localizedName': 'isiXhosa',
    'locale': {
      'flagUrl': '',
      'locale': 'xh-za'
    }
  },
  {
    'languageCode': 'zu',
    'name': 'zulu',
    'localizedName': 'isiZulu',
    'locale': {
      'flagUrl': '',
      'locale': 'zu-za'
    }
  },
  {
    'languageCode': 'ko',
    'name': 'Korean',
    'localizedName': '한국어',
    'locale': {
      'flagUrl': '',
      'locale': 'ko-kr'
    }
  },
  {
    'languageCode': 'eu',
    'name': 'Basque',
    'localizedName': 'Euskara',
    'locale': {
      'flagUrl': '',
      'locale': 'eu-es'
    }
  },
  {
    'languageCode': 'ca',
    'name': 'Catalan',
    'localizedName': 'CatalàCatalà',
    'locale': {
      'flagUrl': '',
      'locale': 'ca-es'
    }
  },
  {
    'languageCode': 'es',
    'name': 'Spanish',
    'localizedName': 'Español',
    'locale': {
      'flagUrl': '',
      'locale': 'es-es'
    },
    'countries': [
      {
        'locale': 'es-ar',
        'flagUrl': ''
      },
      {
        'locale': 'es-mx',
        'flagUrl': ''
      },
      {
        'locale': 'es-cl',
        'flagUrl': ''
      },
      {
        'locale': 'es-co',
        'flagUrl': ''
      },
      {
        'locale': 'es-cr',
        'flagUrl': ''
      },
      {
        'locale': 'es-cu',
        'flagUrl': ''
      },
      {
        'locale': 'es-do',
        'flagUrl': ''
      },
      {
        'locale': 'es-ec',
        'flagUrl': ''
      },
      {
        'locale': 'es-sv',
        'flagUrl': ''
      },
      {
        'locale': 'es-gt',
        'flagUrl': ''
      },
      {
        'locale': 'es-hn',
        'flagUrl': ''
      },
      {
        'locale': 'es-bo',
        'flagUrl': ''
      },
      {
        'locale': 'es-ni',
        'flagUrl': ''
      },
      {
        'locale': 'es-pa',
        'flagUrl': ''
      },
      {
        'locale': 'es-py',
        'flagUrl': ''
      },
      {
        'locale': 'es-pr',
        'flagUrl': ''
      },
      {
        'locale': 'es-uy',
        'flagUrl': ''
      },
      {
        'locale': 'es-ve',
        'flagUrl': ''
      }
    ]
  },
  {
    'languageCode': 'sv',
    'name': 'Swedish',
    'localizedName': 'svenska',
    'locale': {
      'flagUrl': '',
      'locale': 'sv-se'
    }
  },
  {
    'languageCode': 'tg',
    'name': 'Tajik',
    'localizedName': 'тоҷикӣ',
    'locale': {
      'flagUrl': '',
      'locale': 'tg-tj'
    }
  },
  {
    'languageCode': 'th',
    'name': 'Thai',
    'localizedName': 'ไทย',
    'locale': {
      'flagUrl': '',
      'locale': 'th-th'
    }
  },
  {
    'languageCode': 'tr',
    'name': 'Turkish',
    'localizedName': 'Türkçe',
    'locale': {
      'flagUrl': '',
      'locale': 'tr-tr'
    }
  },
  {
    'languageCode': 'uk',
    'name': 'Ukrainian',
    'localizedName': 'українська мова',
    'locale': {
      'flagUrl': '',
      'locale': 'uk-ua'
    }
  },
  {
    'languageCode': 'cy',
    'name': 'Welsh',
    'localizedName': 'Cymraeg',
    'locale': {
      'flagUrl': '',
      'locale': 'cy-gb'
    }
  },
  {
    'languageCode': 'en',
    'name': 'English',
    'localizedName': 'English',
    'locale': {
      'flagUrl': '',
      'locale': 'en-us'
    },
    'countries': [
      {
        'locale': 'en-ca',
        'flagUrl': ''
      },
      {
        'locale': 'en-au',
        'flagUrl': ''
      },
      {
        'locale': 'en-gb',
        'flagUrl': 'United_Kingdom'
      }
    ]
  },
  {
    'languageCode': 'uz',
    'name': 'Uzbek',
    'localizedName': 'Oʻzbek',
    'locale': {
      'flagUrl': '',
      'locale': 'uz-uz'
    }
  },
  {
    'languageCode': 'vi',
    'name': 'Vietnamese',
    'localizedName': 'Việt Nam',
    'locale': {
      'flagUrl': '',
      'locale': 'vi-vn'
    }
  },
  {
    'languageCode': 'ny',
    'name': 'Chichewa',
    'localizedName': 'ChiCheŵa',
    'locale': {
      'flagUrl': '',
      'locale': 'ny-zm'
    }
  },
  {
    'languageCode': 'sn',
    'name': 'Shona',
    'localizedName': 'chiShona',
    'locale': {
      'flagUrl': '',
      'locale': 'sn-zw'
    }
  },
  {
    'languageCode': 'az',
    'name': 'Azerbaijani',
    'localizedName': 'Azərbaycan dili',
    'locale': {
      'flagUrl': '',
      'locale': 'az-az'
    }
  },
  {
    'languageCode': 'cs',
    'name': 'Czech',
    'localizedName': 'čeština',
    'locale': {
      'flagUrl': '',
      'locale': 'cs-cz'
    }
  },
  {
    'languageCode': 'am',
    'name': 'Amharic',
    'localizedName': 'አማርኛ',
    'locale': {
      'flagUrl': '',
      'locale': 'am-et'
    }
  },
  {
    'languageCode': 'ka',
    'name': 'Georgian',
    'localizedName': 'ქართული',
    'locale': {
      'flagUrl': '',
      'locale': 'ka-ge'
    }
  },
  {
    'languageCode': 'ky',
    'name': 'Kyrgyz',
    'localizedName': 'Кыргызча',
    'locale': {
      'flagUrl': '',
      'locale': 'ky-kg'
    }
  },
  {
    'languageCode': 'nl',
    'name': 'Dutch',
    'localizedName': 'Nederlands',
    'locale': {
      'flagUrl': '',
      'locale': 'nl-nl'
    }
  },
  {
    'languageCode': 'ur',
    'name': 'Urdu',
    'localizedName': 'اردو',
    'locale': {
      'flagUrl': '',
      'locale': 'ur-pk'
    }
  },
  {
    'languageCode': 'ar',
    'name': 'Arabic',
    'localizedName': 'العربية',
    'locale': {
      'flagUrl': '',
      'locale': 'ar-ae'
    },
    'countries': [
      {
        'locale': 'ar-dz',
        'flagUrl': ''
      },
      {
        'locale': 'ar-eg',
        'flagUrl': ''
      },
      {
        'locale': 'ar-iq',
        'flagUrl': ''
      },
      {
        'locale': 'ar-jo',
        'flagUrl': ''
      },
      {
        'locale': 'ar-kw',
        'flagUrl': ''
      },
      {
        'locale': 'ar-lb',
        'flagUrl': ''
      },
      {
        'locale': 'ar-ly',
        'flagUrl': ''
      },
      {
        'locale': 'ar-ma',
        'flagUrl': ''
      },
      {
        'locale': 'ar-om',
        'flagUrl': ''
      },
      {
        'locale': 'ar-qa',
        'flagUrl': ''
      },
      {
        'locale': 'ar-sa',
        'flagUrl': ''
      },
      {
        'locale': 'ar-so',
        'flagUrl': ''
      },
      {
        'locale': 'ar-tn',
        'flagUrl': ''
      },
      {
        'locale': 'ar-ye',
        'flagUrl': ''
      }
    ]
  }
]
